import * as React from 'react'
import { DateTime } from 'luxon'
import { navigate } from 'gatsby'
import { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { Section, ReportTimeline, Typography, CardCollapse, Modal } from '../components/library'
import getMoodOptions from '../utils/getMoodOptions'
import getColorFromLevel from '../utils/getColorFromLevel'
import getNameWithPossessiveApostrophe from '../utils/getNameWithPossessiveApostrophe'
import { ImpactContext } from '../components/Layout'

const moods = getMoodOptions()

const useStyles = makeStyles({
  root: {
    fontStyle: 'normal'
  },
  title: {
    textAlign: 'left'
  },
  mood: {
    '& i': {
      fontSize: 40,
      lineHeight: '40px',
      display: 'inline-block',
      verticalAlign: 'middle',
      '&:before': {
        marginLeft: 0
      }
    }
  },
  medication: {
    '& .MuiTypography-root': {
      marginBottom: '0 !important'
    }
  }
})

const AsthmaCheckInDetailViewPage = ({ location }) => {
  if (typeof location === 'undefined' || !location || !location.state) {
    return <></> //needed for static compilation
  }

  const { response } = location.state
  let backTo = typeof location !== 'undefined' && location && location.state?.backTo ? location.state.backTo : -1
  const classes = useStyles()
  const source = {}
  if (response.id) {
    source.id = response.id
  } else if (response.local_id) {
    source.local_id = response.local_id
  }
  const { profiles } = useContext(ImpactContext)
  const checkInDate = DateTime.fromISO(response.attributes.field_checkin_date_iso)
  const asthmaLevel = typeof response.attributes.field_asthma_level === 'number' ? response.attributes.field_asthma_level : null
  const inhalerUsed = response.attributes.field_inhaler_used ? response.attributes.field_inhaler_used : 'no'
  const inhalerDoses = response.attributes.field_inhaler_doses ? response.attributes.field_inhaler_doses : 0
  const fromIndex = response.fromIndex

  const medicationUsed = response.attributes.field_medication_used ? response.attributes.field_medication_used : 'no'
  const medicationUsePeriod = response.attributes.field_medication_use_period ? response.attributes.field_medication_use_period : []
  const mood = asthmaLevel !== null ? moods.find(m => m.value === asthmaLevel) : null
  let symptomsData = null
  if (response?.attributes?.field_symptoms && response.attributes.field_symptoms.length > 0) {
    symptomsData = JSON.parse(response.attributes.field_symptoms)
  }

  let question_label = "your child's"
  if (profiles && profiles?.child && profiles.child?.name && profiles.child.name.length > 0) {
    question_label = getNameWithPossessiveApostrophe(profiles.child)
  }

  return (
    <Modal
      id="check-in-detail-view"
      open={true}
      title="Symptom Report"
      subtitle={checkInDate.toLocaleString(DateTime.DATE_HUGE)}
      handleClose={e => {
        navigate(fromIndex ? '/reports' : backTo)
      }}
    >
      {Array.isArray(symptomsData) && symptomsData.length > 0 ? (
        <ReportTimeline data={symptomsData} />
      ) : (
        <CardCollapse title="Symptoms" number={0} lock />
      )}
      {mood !== null && (
        <>
          <Section>
            <Typography variant="body1" shade="dark">
              How's {question_label} asthma today?
            </Typography>
            <Typography color={getColorFromLevel(asthmaLevel)} className={classes.mood}>
              {mood.label}
            </Typography>
          </Section>
        </>
      )}
      <Section title="Medication">
        <div className={classes.medication}>
          <Typography variant="body1" shade="dark">
            Did you take your rescue inhaler?
          </Typography>
          <Typography color="primary">
            <strong>{inhalerUsed === 'yes' ? 'Yes' : 'No'}</strong>
          </Typography>
          {inhalerUsed === 'yes' && (
            <>
              <Typography variant="caption">
                {inhalerDoses} {inhalerDoses && inhalerDoses > 1 ? 'doses' : 'dose'} used
              </Typography>
              <Typography variant="caption">Last dose at {response.attributes.field_last_dose_str}</Typography>
            </>
          )}

          <Typography variant="body1" shade="dark">
            Did you take your daily medication?
          </Typography>
          <Typography color="primary">
            <strong>{medicationUsed === 'yes' ? 'Yes' : 'No'}</strong>
          </Typography>
          {medicationUsed === 'yes' && (
            <Typography variant="caption">Taken in {medicationUsePeriod ? medicationUsePeriod.join(', ') : ''}</Typography>
          )}
        </div>
      </Section>
    </Modal>
  )
}

export default AsthmaCheckInDetailViewPage
